<template>
  <div class="locations p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-between">
        <h1 class="text-3xl font-semibold ">
          Locations
        </h1>
        <router-link
          to="/locations/add"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
        >
          <span class="hover-target pt-1 font-medium">Add</span>
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </router-link>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div>
        <div class="flex gap-3 justify-end mb-1 items-end">
          <label for="customer_search" class="text-gray-500 text-sm">
            Search:
          </label>
          <input
            v-model="search"
            type="text"
            name="customer_search"
            class="px-2 py-0 min-w-64 border-0 border-b border-gray-200 text-gray-500 focus:outline-none focus:ring-transparent"
            autocomplete="off"
          />
        </div>

        <div class="shadow overflow-hidden border border-gray-200">
          <table class="w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ID
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Location Address 1
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Location Address 2
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Location City
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Location State
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Location Zip
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Notes
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Customers
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Quotes
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="location in filteredLocations"
                :key="location.id"
                @click="goTo(`/locations/${location.id}`)"
                class="hover:bg-gray-50"
              >
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  {{ location.id }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ location.location_address1 }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ location.location_address2 }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ location.location_city }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ location.location_state }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ location.location_zip }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap"
                >
                  {{ location.location_notes }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <ul>
                    <li
                      v-for="customer in location.customers"
                      :key="customer.id"
                    >
                      {{ customer }}
                    </li>
                  </ul>
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <ul>
                    <li v-for="quote in location.quotes" :key="quote.id">
                      {{ quote }}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";

export default {
  props: ["user"],
  setup(props) {
    const isLoading = ref(true);
    const router = useRouter();
    const search = ref(null);

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, documents: locations } = getCollection(
      `businesses/${business_id}/locations`
    );

    const filteredLocations = computed(() => {
      if (search.value) {
        return locations.value.filter(
          (l) =>
            l.location_address1
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_address2
              ?.toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_city
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_state
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_zip.replace(/[^0-9]/g, "").includes(search.value)
        );
      }
      return locations.value;
    });

    watchEffect(() => {
      if (locations.value) {
        isLoading.value = false;
      }
    });

    const goTo = (url) => {
      router.push(url);
    };

    return {
      isLoading,
      search,
      error,
      locations,
      filteredLocations,
      goTo,
    };
  },
};
</script>
